import React from 'react'
import {
  Heading,
  SimpleGrid,
  Box,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { PageProps, graphql } from 'gatsby'
import { BlogShort } from 'graphqlTypes'

import Layout from 'components/Layout'
import Seo from 'components/Seo'
import BlogCard from 'components/BlogCard'

interface Data {
  allContentfulPost: {
    edges: Array<{
      node: BlogShort
    }>
  }
}

const BlogPage: React.FC<PageProps<Data>> = ({ data }) => {
  return (
    <Layout>
      <Seo title="Blog" />
      <Box
        as="section"
        bg={mode('gray.50', 'gray.800')}
        py={{ base: '10', sm: '24' }}
      >
        <Box
          maxW={{ base: 'xl', md: '7xl' }}
          mx="auto"
          px={{ base: '6', md: '8' }}
        >
          <Heading
            size="xl"
            mb="8"
            fontWeight="extrabold"
            color={mode('gray.800', 'gray.400')}
          >
            All Articles
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing="12" mb="10">
            {data.allContentfulPost.edges.map((blog) => (
              <BlogCard data={blog.node} key={blog.node.slug} />
            ))}
          </SimpleGrid>
        </Box>
      </Box>
    </Layout>
  )
}

export default BlogPage

export const pageQuery = graphql`
  query GetAllPost {
    allContentfulPost(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          createdAt(formatString: "DD/MM/YYYY")
          slug
          title {
            title
          }
          author {
            name
            avatar {
              fixed(cropFocus: CENTER, height: 120, width: 120) {
                src
                srcSet
                width
                height
              }
            }
          }
          heroImage {
            fixed(width: 600) {
              src
              srcSet
              width
              height
            }
          }
          description {
            description
          }
        }
      }
    }
  }
`
